import React from 'react';
import { GridContainer, GridItemContainer } from './Grid.styles';

const Grid = ({ children, ...props }): React.ReactElement => {
  return <GridContainer {...props}>{children}</GridContainer>;
};

Grid.Item = ({ children, ...props }): React.ReactElement => {
  return <GridItemContainer {...props}>{children}</GridItemContainer>;
};

export default Grid;
