import styled from 'styled-components';
import { CloseButton } from './close/styled';

export const Wrapper = styled.div``;
export const WrapperMobile = styled.div`
  padding: 0 12px 32px 12px;
  ${CloseButton} {
    padding-top: 8px;
    align-items: flex-start;
  }
`;

export const Band = styled.div<{ color: string }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  line-height: 44px;
  position: relative;
  padding-right: 5px;

  ${(props) => `
        background-color: ${props.color};
        height: 44px;
    `}

  & > a {
    width: 100%;
  }
`;
export const BandSkelton = styled.div`
  width: 100%;
  height: 50px;
  background-color: #efefef;
`;

export const BandTitle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  width: calc(100% - 60px);
  ${(props) => `color: ${props.color || 'black'};`}
  font-size: 14px;
`;

export const BandMobile = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background: #313135;
  border: 1px solid #18181b;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
export const BandTitleMobile = styled.div`
  display: flex;
  margin: 12px 0;
  justify-content: center;
  width: calc(100% - 84px);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  word-break: keep-all;
  & span {
    color: #ffffff !important;
  }
`;
