import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const theme = createTheme({
  palette: {
    // @ts-ignore
    primary: {
      main: '#f300ba',
    },
    // @ts-ignore
    secondary: {
      main: '#4E4EFF',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: '10px',
          '& .MuiSvgIcon-root': {
            height: 1,
            width: 1,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1296,
    },
  },
});
export default function ThemeMui({ children }: Props) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
