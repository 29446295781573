import React, { ReactElement, ReactNode, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { appWithTranslation, TFunction, useTranslation } from 'next-i18next';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { useLocalStorage, useSessionStorage } from 'react-use';
import { NextPage } from 'next';
import Head from 'next/head';
import Page from '@components/Page';
import { useApollo } from '@lib/apolloClient';
import 'tailwindcss/tailwind.css';
import 'reflect-metadata';
import 'es6-shim';
import { SettingsProvider } from 'src/context/SettingContext';
import ThemeSettings from '@components/settings';
import { FacebookTag, GoogleTag, Layout } from '@components/layout';
import * as mixpanel from '@lib/mixpanel';
import { ModalGroup } from '@components/common';
import { useGA4PageView } from '@hooks/logger';
import { GoogleAnalyticsTag, TwitterPixelTag } from '@components/layout/Marketing/Marketing';
import { HistoryProvider } from 'src/provider/HistoryProvider';
import { convertLangCodeForDB } from '@lib';
import { CommonProvider } from 'src/provider';
import { campaignParams } from '@lib/mixpanel';
import Errors from '@components/Errors/Errors';
import { ErrorBoundary } from '@components/Errors/ErrorBoundary';
import { ThemeMode } from '@components/settings/type';
import { CATEGORY_CODE, IS_PROD, LANGUAGE_CODE, PAGE_TYPE } from '../src/constants';
import nextI18NextConfig from '../next-i18next.config.js';

ErrorBoundary.displayName = 'ErrorBoundary';

interface GlobalTranslation {
  t?: TFunction | ((arg: any) => any);
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

const getPageType = (url: string) => {
  if (/\/toon$/.test(url)) return 'main';
  else if (url.includes('best')) return 'best';
  else if (url.includes('comet-deal')) return 'comet-deal';
  else if (url.includes('new')) return 'new';
  else if (url.includes('search')) return 'search';
  else if (url.includes('cosmic-deal')) return 'cosmic-deal';
  else if (url.includes('photoreviews')) return 'photo reviews';
  else if (url.includes(`/category/${CATEGORY_CODE.ABLER}`)) return 'abler';
  else if (url.includes('intro')) return 'intro';
  else if (url.includes('brochure')) return 'brochure';
  else if (url.includes('game')) return 'game';
  else if (url.includes('/users/join')) return 'signup';
  else if (url.includes('/users/login')) return 'signin';
  return null;
};

export const globalTranslation: GlobalTranslation = { t: (str) => str };

function MyApp({ Component, pageProps, router: serverRouter }: MyAppProps): React.ReactElement {

  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage('acon-auth-token', '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [returnUrl, setReturnUrl] = useSessionStorage('returnUrl');

  const getLayout = Component.getLayout ?? ((page) => page);

  // cookie
  const [cookies, setCookie, removeCookie] = useCookies();
  // 번역도구
  const { t, i18n } = useTranslation();
  globalTranslation.t = t;
  const router = useRouter();

  // const errorBoundaryRef = useRef('errorBoundaryRef');
  // axiosErrorHandler((error) => {
  //   errorBoundaryRef.current.setState({ error });
  // });

  const changeReturnUrl = (url: string) => {
    if (!url || url.includes('/users/login') || url.includes('/users/join')) return;

    const splitUrl = [
      '',
      ...(url
        .split('/')
        ?.filter(Boolean)
        ?.filter((item) => {
          if ([LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.CN, LANGUAGE_CODE.JP].includes(item as LANGUAGE_CODE)) return false;
          if ([PAGE_TYPE.GAME, PAGE_TYPE.TOON].includes(item)) return false;
          return true;
        }) || []),
    ];

    const joinUrl = splitUrl.length === 1 ? '/' : splitUrl.join('/');
    if (joinUrl) setReturnUrl(joinUrl);
  };

  useEffect(() => {
    if (i18n.language) {
      const utmLastTouchParams = campaignParams();
      mixpanel.setSuperProperty({
        'Mall (language)': i18n.language,
        ...utmLastTouchParams,
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Send track event when new pages is loaded

      changeReturnUrl(url);
      // 퍼널에서 비교 분석 용이하게 하기 위해서 event name 페이지 url에 따라 변경
      const pageType = getPageType(url);

      mixpanel.event('Page view', {
        url,
      });
      if (pageType) {
        mixpanel.event(`Page view_${pageType}`, {
          url,
        });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // initialize apollo client
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apolloClient = useApollo(pageProps.initialApolloState, {
    authToken,
    setAuthToken,
    removeAuthToken,
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;
    (() => {
    })(); // 의미없는 코드로 리빌드 유발
    // start of 임시코드!! 2023.04.08 정도 까지 유지. 더이상 사용하지 않는 쿠키를 지워서, 헤더가 길어지는 것을 방지
    const cookieKeys = Object.keys(cookies);
    const removeCookieTargets = [
      '_hackle_hid',
      ...cookieKeys.filter(
        (c) =>
          (c.startsWith('Hm') && !c.includes('8c5bd5a9ef1154d261166ccbdcc638c8')) ||
          (c.includes('mixpanel') && !c.includes('23fa79e73c2ad93b70444f3a36fcaa0c')) ||
          (c.includes('_ga_') && !c.includes(process.env.NEXT_PUBLIC_GA4_ID.split('-')[1]))
      ),
    ];

    for (const removeCookieTarget of removeCookieTargets) {
      removeCookie(removeCookieTarget, { path: '/', domain: '.acon3d.com' });
    }
    // end of 임시코드!!

    if (IS_PROD) {
      datadogRum.init({
        applicationId: '72a2102c-0ed0-4f27-979a-50589b2f8328',
        clientToken: 'pubf54da44c5172edad20fd3df79025910e',
        site: 'datadoghq.com',
        service: 'acon3d',
        env: 'production',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 10,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
      });
      datadogRum.startSessionReplayRecording();
    }

    const url = window.location?.href?.replace(window.location?.origin, '');
    if (!url) return;
    changeReturnUrl(url);
    const pageType = getPageType(url);
    mixpanel.event('Page view', { url });
    if (pageType) mixpanel.event(`Page view_${pageType}`, { url });
  }, []);


  useEffect(() => {
    // SSR
    if (typeof window === 'undefined') return;

    /**
     * URL에 언어 설정이 포함되어 있을 경우 해당 언어로 쿠키를 설정합니다.
     * v2 언어 설정을 위해 NEXT_LOCALE 쿠키를 설정하고
     * 기존 고도몰과 연동을 위해 lang 쿠키도 같이 설정합니다.
     */
    if (router.locale !== 'acon') {
      // 쿠키 만료일자
      const expires = new Date();
      // 쿠키 만료일자를 내년으로 설정
      expires.setFullYear(expires.getFullYear() + 1);

      // 현재 접근한 URL로 NEXT_LOCALE 쿠키를 설정합니다.
      if (i18n.language) setCookie('NEXT_LOCALE', i18n.language, { path: '/', expires });

      // 고도몰 언어 설정에 맞게 언어 코드 변환
      const parseLang = convertLangCodeForDB(i18n.language);

      // 현재 설정된 쿠키가 현재 URL로 설정된 언어와 다를 경우 URL 기준으로 쿠키 재설정
      // 고도몰과 호환을 위해 설정
      if (parseLang && cookies.lang !== parseLang) {
        // 쿠키 설정
        setCookie('lang', parseLang, { path: '/', expires, domain: '.acon3d.com' });
      }
    }
  }, [i18n.language]);

  useGA4PageView();
  const findParent = (element: HTMLElement, finder: (el: HTMLElement) => boolean): HTMLElement => {
    if (!element) return null;
    if (!finder(element)) return findParent(element.parentElement, finder);
    return element;
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleClickMixpanel = (e) => {
        const mixpanelDatasetHolder = findParent(e.target as HTMLElement, (el) => el?.dataset?.mixpanelAction && el.dataset.mixpanelAction === 'click');
        if (mixpanelDatasetHolder) {
          const event = Object.entries(mixpanelDatasetHolder.dataset)
            .map(([k, v]) => {
              const key = k.replace(/^mixpanel/, '');
              return [key.charAt(0).toLowerCase() + key.slice(1), v];
            })
            .filter(([k]) => k !== 'action' && k !== 'collectOnce')
            .reduce((obj, [k, v]) => ({ ...obj, [`${k}`]: v }), {}) as {
            evt: string;
            [key: string]: string;
          };
          const eventNames = event.evt.split(', ');
          delete event.evt;

          eventNames.forEach((eventName) => mixpanel.event(eventName, event));

          // 한 번만 수집하게 acion attribute를 제거
          if (mixpanelDatasetHolder.dataset?.mixpanelCollectOnce && mixpanelDatasetHolder.dataset.mixpanelCollectOnce === 'true') {
            mixpanelDatasetHolder.removeAttribute('data-mixpanel-action');
          }
        }
      };
      window.addEventListener('click', handleClickMixpanel);

      return () => {
        window.removeEventListener('click', handleClickMixpanel);
      };
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <CookiesProvider>
        <CommonProvider
          tokenStorageHook={{
            authToken,
            setAuthToken,
            removeAuthToken,
          }}
        >
          <SettingsProvider themeMode={(serverRouter?.pathname.includes('/game') ? 'game' : 'toon') as ThemeMode}>
            <HistoryProvider>
              <ThemeSettings>
                <Head>
                  {/* 실제 프로덕트 환경에서만 마케팅에서 사용하는 태그들 사용 */}
                  {IS_PROD &&
                    (i18n.language === LANGUAGE_CODE.CN ? (
                      <>
                        <GoogleTag />
                      </>
                    ) : (
                      <>
                        <GoogleTag />
                        <FacebookTag />
                      </>
                    ))}
                  <GoogleAnalyticsTag />
                  <TwitterPixelTag />
                </Head>
                <Script strategy="afterInteractive" src="https://www.googleoptimize.com/optimize.js?id=OPT-M9WJW4J" />
                <Script strategy="afterInteractive" type="text/javascript" src="/scripts/jquery-1.12.4.min.js" />
                <Script strategy="afterInteractive" type="text/javascript" src="/scripts/iamport.payment-1.2.1.js" />

                <ErrorBoundary
                  fallback={(props) => <Layout><Page noUseRouteReady={true}><Errors {...props} /></Page></Layout>}>
                  <ModalGroup />
                  {getLayout(<Component {...pageProps} />)}
                </ErrorBoundary>
              </ThemeSettings>
            </HistoryProvider>
          </SettingsProvider>
        </CommonProvider>
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
